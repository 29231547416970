.Cell {
  --size: calc(4vw);
  --min-size: 30px;
  --max_size: calc(8vh);

  min-width: var(--min-size);
  max-width: var(--max_size);
  min-height: var(--min-size);
  max-height: var(--max-size);

  width: var(--size);
  height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px lightslategray dotted;
  background-image: linear-gradient(135deg, var(--selected), var(--occupied));  
}

.LightMode {
  background-color: white;
}

.LightSelected {
  background-color: goldenrod;
}

.LightOccupied {
  background-color: #98C1D9;
}

.DarkMode {
  background-color: #BDD5EA;
}

.DarkSelected {
  background-color: goldenrod;
}

.DarkOccupied {
  background-color: #577399;
}