.BlinkingBackground {
  animation: blink 1.5s infinite linear;
  background-color: transparent;
  border-radius: 10px;
}

@keyframes blink {
  50% {
    background-color: gray;
  }
}

.Boards {
  display: flex;
  flex-direction: row;
  align-items: "center";
  justify-content: "center",
}

@media (max-width: 700px) {
  .Boards {
    display: flex;
    flex-direction: column;
    align-items: "center";
    justify-content: "center",
  }
}