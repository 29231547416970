html, body {
  --light-bg-color: white;
  --dark-bg-color: #293241;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.LightMode {
  background-color: var(--light-bg-color);
}

.DarkMode {
  background-color: var(--dark-bg-color);
}

.FadeInComponentSlowly {
  animation: fadeIn 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.FadeInComponentQuickly {
  animation: fadeIn 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}